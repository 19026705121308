#company-profile {
    font-family: proxima-nova, sans-serif;
    width: 1045px;
    background: #FFFFFF;
    border: 0.5px solid #DCDCDC;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    margin-bottom: 20px;
}

.nav-tabs .nav-link {
    border: 0px solid transparent;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
}

a.active.nav-link.active {
    width: fit-content;
    border-bottom: 5px solid #DA1500;
}

div.CustomerDetailPageHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

div.CustomerDetailPageheaderButton {
    display: flex;
}
