.col-sm-8 {
    width: 752px;
    font-family: proxima-nova, sans-serif;
}

div.main-column.col-sm-8{
    border-left: 1px solid #DCDCDC;
}

.subtitle {
    font-weight: normal;
}

h6.padding-default.card-title {
    background: rgba(0, 0, 0, 0.05);
}

div.modal-content {
    font-family: proxima-nova, sans-serif;
}

button.btn.btn-primary {
    border: 1px solid #DA1500;
    background: #FFFFFF;
    border-radius: 27px;
    font-weight: bold;
    color: #DA1500;
}

button.btn.btn-secondary {
    background: #DA1500;
    border-radius: 27px;
    font-weight: bold;
}

div.modal-footer {
    justify-content: center;
}

div#delete-body.modal-body {
    display: block;
    text-align: center;
}

input.form-control, select.form-control {
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-sizing: border-box;
    border-radius: 4px;
}

div.registration-activity-info {
    border-top: 1px solid #DCDCDC;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #DA1500;
    font-weight: bold;
}

img.padding-default {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

div.license-action, div.service-action {
    display: flex;
    justify-content: space-evenly;
}