nav.navbar-expand-sm {
    border-bottom: 0px;
    margin-bottom: 0px;
}

/*div.container {
    padding-left: 0px;
    margin-left: 44px;
}*/

div.App {
    height: 88vh;
    width: 80vw;
}

div.data-area-div {
    display: flex;
    align-items: center;
    height: 100%;
}

div#login-area {
    width: 400px;
    background: #FFFFFF;
    height: 100%;
    display: flex;
    justify-content: center;
}

div#login-container {
    padding-top: 266px;
}

div#login-header {
    display: flex;
    justify-content: center;
    margin-bottom: 46px;
}

h3#loginheader-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
}

div#login-button {
    display: flex;
    justify-content: center;
    width: 307px;
}

button.login-button {
    background: #DA1500;
    border-radius: 3px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
}

div#image-area {
    height: 617px;
}

div#image-container {
    padding-top: 160px;
}

img#login-image {
    width: 500px;
    height: 324px;
    left: 762px;
    top: 236px;
}