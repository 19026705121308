div.Available-section {
    background: #FFFFFF;
    border: 0.5px solid #DCDCDC;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
}

a.active {
    border-top-left-radius: 7px;
}

div.servicePageTable {
    border: 0px;
    left: 294px;
    top: 136px;
    background: #FFFFFF;
    border-radius: 7px;
    border: 0px !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07) !important;
}
