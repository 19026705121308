div#Customer-Name {
    display: flex;
    justify-content: normal;
    height: none;
}

div#CustomerName-Text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

td#CustName-Text {
    border: 0px;
}

td {
    vertical-align: middle;
}