div.ticketListContainer {
    width: 1045px;
}

select.filterStatus {
    margin-left: 5px;
}

div.ticketListContainer th {
    text-align: left !important;
    white-space: nowrap !important;
}

div.ticketListContainer td {
    text-align: left !important;
}

div.ticketListContainer td:nth-child(6), div.ticketListContainer td:nth-child(7)  {
    white-space: nowrap;
}

div#CompanyName {
    display: flex;
}

td#CompName-Text {
    border: 0px !important;
    white-space: nowrap;
}

/*mobile responsive part*/
@media (max-width: 768px) {
    div.ticketListContainer {
        width: 350px;
    }
}

@media (max-width: 393px) {
    div.ticketListContainer {
        width: 330px;
    }
}

@media (max-width: 375px) {
    div.ticketListContainer {
        width: 300px;
    }
}
