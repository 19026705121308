div.reportPageTable {
    border: 0px;
    left: 294px;
    top: 136px;
    background: #FFFFFF;
    border-radius: 7px;
    border: 0px !important;
}


div.reportPageTable th {
    white-space: nowrap;
    text-align: left !important;
}

div.reportPageTable td {
    text-align: left !important;
}
