div.container-fluid {
    background: #FFFFFF;
    border: 0.5px solid #DCDCDC;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.07);
    border-radius: 7px;

    font-family: proxima-nova, sans-serif;
}

div.main-row.row, div.side-row.row {
    padding: 0px;
}


div.row-component {
    padding: 15px;
}
    

div.main-col {
    border-right: 1px solid #DCDCDC;
}

div.Customer-info.col-lg-12 {
    border-bottom: 1px solid #DCDCDC;
    display: inline-grid;
    justify-content: center;
    padding-top: 15px;
}

div.Customer-logo {
    padding: 32px;
}

div.Customer-logo, div.Customer-name,div.Info-title, div.Customer-status {
    display: flex;
    justify-content: center;
}

div.Info-title {
    padding: 10px;
}

div#Customer-email, div#Customer-contact, div#Customer-location {
    padding: 10px;
}

div#Customer-location {
    display: flex;
}

div#location-icon {
    padding-right: 8px;
}

div#Cust-Status {
    width: 5px;
}

div.Status {
    display: flex;
    justify-content: flex-end;
}

div.statusOfTicket {
    width: 96px;
    height: 29px;
    border: 0.5px solid #DCDCDC;
    display: flex;
    justify-content: center !important;
}

div#ticket-status.row {
    padding: 0px;
}

button#Reso-Dropdown {
    background: #000000;
    color: #FFFFFF;
    border-radius: 27px;
}

div.Resolution-time {
    display: flex;
    justify-content: flex-end;
    padding: 0px;
}

button.dropdown-item {
    display: flex;
    justify-content: center;
}

div#resolution-time {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 180%;
    align-items: center;
    text-align: center;
    background: #000000;
    border-radius: 27px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    height: 36px;
}

div#status {
    display: flex;
    justify-content: flex-end;
}

select#status {
    width: 162px;
    height: 29px;
    background: #FFFFFF;
    border: 0.5px solid #DCDCDC;
    border-radius: 3px;
}

div#header-end {
    border-bottom: 1px solid #DCDCDC;
    padding-bottom: 15px;
}

img.customer-pic {
    border-radius: 50%;
    width: 50px;
}

div#message-header {
    display: flex; 
    align-items: center;
}

div#date-col {
    display: flex; 
    align-items: center; 
    justify-content: flex-end;
}

div#message-sender {
    padding-left: 10px;
}

div#file-upload-col {
    padding: 0px;
}

div.file-upload {
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    border-radius: 3px;
}

div#upload-content {
    display: flex;
    flex-wrap: wrap;
    opacity: 0.7;
}

div.download-button {
    opacity: 0.7;
}

div.content, div.file-counter, div.download-button {
    padding: 15px;
}

div#uploadDocument {
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
}

div#reply-status {
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background: rgba(0, 0, 0, 0.05);
    width: fit-content;
    border-radius: 5px;
}

div.Chatbox {
    border: 1px solid #DCDCDC;
    padding: 0px;
    border-radius: 3px;
}


textarea#chatarea {
    border-left: none;
    border-right: none;
    border-radius: 0%;
}

div#chat-title {
    padding: 10px;
    border-bottom: 5px solid #DA1500;
    width: fit-content;
    margin-left: 20px;
    color: #DA1500;
    font-weight: bold;
}

div.CallDateTime-status {
    /*margin-left: 10px;
    background: rgba(0, 0, 0, 0.05);
    width: fit-content;
    border-radius: 2px;*/

    width: fit-content;
    background: black;
    color: white;
    cursor: pointer;
    border-radius: 16px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    line-height: 30px;
}



div.Chatbox-footer {
    display: flex;
}

div.footer-component {
    padding: 10px;
}

button#reply-button {
    background: #DA1500;
    border-radius: 27px;
    color: #FFFFFF;
}

/*mobile responsive part*/
@media (max-width: 414px) {
    div.ticketStatus-header {
        display: flex;
        justify-content: space-between;
    }
}


