div.AuditLogHeader {
    display: flex;
    justify-content: flex-end !important;
}

div.AuditLogButton {
    margin-right: 5px;
}

div.AuditLogButton btn {
    margin-right: 2px;
}

div.auditLogsTable {
    border: 0px;
    left: 294px;
    top: 136px;
    background: #FFFFFF;
    border-radius: 7px;
    border: 0px !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07) !important;
}