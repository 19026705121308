div.card-header {
    display: flex;
    position: relative;
    justify-content: space-between;
    background: none;
    border-bottom: none;
}

div.totalTickets {
    margin-top: 10px;
    height: 20px;
    margin-right: 5px;
}

div.totalTicketsInternal {
    margin-top: 2px;
    height: 20px;
    margin-right: 5px;
}

div.card-options {
    display: flex;
    justify-content: space-between
}

div.filter-options {
    display: flex;
    margin-top: 6px;
}

button.btn {
    margin-left: 5px;
}

div.tableContainer {
    border: 0.5px solid #DCDCDC;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.07);
}
