#pagination {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.pageBtn {
    background-color: red;
    color: #FFFFFF;
    border-radius: 7px;
    width: 30px;
    height: 30px;
    margin: 1px;
}

.activeBtn {
    width: 30px;
    height: 30px;
    border-radius: 7px;
    color: #FFFFFF;
    background-color: #DA1500;
    margin: 1px;
}

.pageBtn:disabled {
    background-color: #a0a3bd;
    cursor: not-allowed;
    opacity: 0.5;
}
