header.navbar-theme {
    position: fixed;
    width: 100%;
    z-index: 3;
    height: 65px;
}


a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.navbar-theme {
    background: #DA1500;
    color: #ffffff !important;
}

header.navbar-theme > nav {
    padding: 0px;
    border-bottom: none !important;
}

div.TopNavbarContainer {
    margin-left: 0px !important;
}

@font-face {
    font-family: "Azonix";
    src: url("../font/Azonix.otf");
}

div.TopNavbarContainer span {
    display: flex;
}

div.TopNavbarContainer span p {
    font-family: Azonix;
}

button.SideBarToggler {
    border-radius: 7px !important;
    margin-left: -22px;
}

div.NovaTitle {
    /*margin-left: 180px;*/
    font-family: "Azonix";
    display: flex;
}

i.fas.fa-caret-up > img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
}

nav.mini-sidebar {
    width: 80px;
}

/*mobile responsive part*/
@media (max-width: 576px) {
    div.TopNavbarContainer > span {
        display: none;
    }

    button.SideBarToggler {
        margin-left: 10px;
    }

    div.NovaTitle h5:nth-child(2) {
        display: none
    }

    div.rightsidebar nav.col-md-2 {
        width: 144px;
    }

    div.rightsidebar nav.mini-sidebar {
        width: 62px;
    }

    div.sidebar-sticky a {
        white-space: nowrap;
    }

    div.sidebar-sticky {
        overflow-x: auto;
    }
}

@media (max-width: 768px) {
    div.sidebar-sticky  a {
        white-space: nowrap;
    }

    div.sidebar-sticky {
        overflow-x: auto;
    }
}

@media (max-width: 576px) {
    div.TopNavbarContainer > span {
        display: none;
    }

    button.SideBarToggler {
        margin-left: 10px;
    }

    div.NovaTitle h5:nth-child(2) {
        display: none
    }

    div.TopNavbarContainer div.dropdown {
        margin-right: 10px;
    }
}

@media (max-width: 1024px) {
    div.sidebar-sticky a {
        white-space: nowrap;
    }
}

