h5.subscriptionTitle {
    padding-left: 18px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

div.borderlessContainer {
    border: 0px;
    left: 294px;
    top: 136px;
    background: #FFFFFF;
    border-radius: 7px;
    border: 0px !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07) !important;
}

div.borderlessContainer th:first-child, td:first-child{
    text-align:left;
    padding-left: 18px;
}

div.borderlessContainer th:nth-child(2) td:nth-child(2) {
    text-align: left;
}

div.borderlessContainer th:last-child {
    display: flex;
    justify-content:flex-end;
    padding-right: 50px;
}

div.borderlessContainer td:last-child {
    display: flex;
    justify-content: flex-end;
}

#licenseTable th:nth-child(2), #licenseTable td:nth-child(2) {
    text-align: left;
}

#supportTable th:nth-child(2), #supportTable td:nth-child(2) {
    min-width: 176px;
    text-align: left;
}

#supportTable th:nth-child(3), #supportTable td:nth-child(3) {
    text-align: left;
}

/*Styling for address Button*/
div.SubcriptionAddButton {
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
}

/*mobile responsive part*/
@media (max-width: 768px) {
    div.tab-content {
        overflow-x: scroll;
    }
}

@media (max-width: 393px) {

    div.tab-content {
        overflow-x: scroll;
    }
}

@media (max-width: 375px) {

    div.tab-content {
        overflow-x: scroll;
    }
}
