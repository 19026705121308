


div.tableContainer {
    /*width: 1000px;*/
    /*height: 400px;*/
    left: 294px;
    top: 136px;
    background: #FFFFFF;
    /*border: 0.5px solid #DCDCDC;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.07);*/
    border-radius: 7px;
}

img.logo-image {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.font-icon {
    width: 15px;
    height: 15px;
}

tr {
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    height: 47px;

   
}

th {
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.table th {
    text-align: center;
    border-top: none;
}

.table td {
    text-align: center;
    border-bottom: 1px solid #dee2e6;

    height: 47px;
}

.action-button {
    display: flex;
    justify-content:space-around;
}
/*
.tableContainer tr {
    position: absolute;
    width: 66px;
    height: 14px;
    left: 316px;
    top: 206px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */
/*
    display: flex;
    align-items: center;
    letter-spacing: 0.001em;
    color: rgba(0, 0, 0, 0.6);
}
*/
.tableContainer #search {
    float: right;
    width: 158.66px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    /*height: 15.66px;*/
    left: 1164px;
    /*top: 161px;*/
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135.6%;
    /* or 19px */



    display: flex;
    align-items: center;
    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.4);
}

.tableContainer #showEntries {
    float: left;
    padding-top: 18px;
    padding-left: 18px;
    /*display: inline;*/
    /*width: 37px;*/
    /*height: 16px;*/
    left: 316px;
    top: 161px;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135.6%;
    /* or 19px */

    display: inline;
    align-items: center;
    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.4);
}

#table-footer {
    align-items:center;
    padding-bottom: 41px;
}

.tableContainer #entriesShown {
    /*width: 164px;*/
    /*height: 14px;*/
    float: left;
    padding-left: 18px;
    left: 316px;
    top: 554px;
    overflow: hidden;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 240%;
    /* or 25px */

    display: flex;
    align-items: center;
    letter-spacing: 0.001em;
    color: rgba(0, 0, 0, 0.6);
}

.tableContainer #pageButton {
    display: flex;
    float: right;
    /*padding-left: 44px;*/
    /*padding-right: 19px;*/
    /*width: 59px;*/
    /*height: 14px;*/
    left: 1123px;
    top: 554px;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
    /* or 25px */

    /*display: inline;*/
    align-items: center;
    letter-spacing: 0.001em;
    color: rgba(0, 0, 0, 0.6);
}

#paginationButton .nav-link {
    display: inline-block;
    color: rgba(0,0,0,0.3);
}

#paginationButton .nav-pills .nav-link.active {
    width: 37px;
    height: 37px;
    border-radius: 7px;
    color: #FFFFFF;
    background-color: #DA1500;
}

#previous, #next {
    padding-bottom: 4px;
}

#search > input {
    border-left: transparent;
    border-top: transparent;
    border-right: transparent;
    border-radius: 0;
}

.pagination {
    cursor: pointer;
}

.pagination__link {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #DA1500;
    border-radius: 7px;
    color: rgba(0, 0, 0, 0.3);
    
}

a.pagination__link {
    color: rgba(0, 0, 0, 0.3);
}

.pagination__link--previous {
    color: rgba(0, 0, 0, 0.3);
}
.pagination__link--next {
    color: rgba(0, 0, 0, 0.3);
}
.pagination__link--disable {
    color: rgba(0, 0, 0, 0.3);
}

li.pagination__link--active > a.pagination__link{
    font-weight: 600;
    color: #FFFFFF !important;
    /*background: red;*/
}

.pagination > li {
    margin: 0 5px;
}

/*mobile responsive part*/
@media (min-width: 576px) {
    div#table-footer {
        display: block;
        /*margin-left: 125px;*/
    }

    div#entriesShown {
        display: flex;
        justify-content: center;
        float: none;
    }

    div#pageButton {
        display: flex;
        justify-content: center;
        float: none;
    }
}

@media (max-width: 576px) {
    div#table-footer {
        display: block;
        /*margin-left: 125px;*/
    }

    div#entriesShown {
        display: flex !important;
        justify-content: center !important;
        float: none !important;
    }

    div#pageButton {
        display: flex !important;
        justify-content: center !important;
        float: none !important;
    }
}