div.Dashboard-container {
    font-family: proxima-nova, sans-serif;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

div#Card-row {
    padding-bottom: 15px;
}

div.card-header {
    font-family: proxima-nova, sans-serif;
    display: flex;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.15);*/
}

div.header-left, div.header-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

div.header-left > h6 {
    font-weight: bold;
}


div.select-company {
    display: flex;
    padding-right: 5px;
}

div.select-company > select {
    border: 0px;
    background: transparent;
    font-weight: bold;
}

div.select-frequency {
    background: #FFFFFF;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.07);
    border-radius: 40px;
    white-space: nowrap;
}

div.select-frequency > button.active {
    background: #DA1500;
    border-radius: 40px;
    color: #FFFFFF;
    font-weight: bold;
}


div.Status-card {
    align-items: center;
    min-width: 225px;
    height: 135px;
    display: flex;
    justify-content: center;
    border: 0.5px solid #DCDCDC;
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
}

p.Status {
    font-size: 40px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 0px;
}

p#Status-opened {
    color: #00ABF0;
}

p#Status-attended {
    color: #FAA73B;
}

p#Status-inprogress {
    color: #00BBA8;
}

p#Status-closed {
    color: #FB26D7;
}

div.Graph-Container {
    padding: 15px;
    border: 0.5px solid #DCDCDC;
    box-shadow: 0px 0px 22px rgb(0 0 0 / 7%);
    border-radius: 7px;
    background: #FFFFFF;
}

div.TotalTicket-card {
    width: 150px;
    height: 100px;
    border: 0px;
}

p.Total-Ticket {
    font-size: 41px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 0px;
}

p#Total-Ticket {
    color: #DA1500;
}

div.Bottom-Container {
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

div.Graph-bottom {
    margin-left: 15px;
}

div.Pie-Chart {
    margin-bottom: 55px;
}

div.PieChart-footer {
    text-align: center;
}

div.PieChart-footer > h5 {
    font-weight: bold;
}

/*mobile responsive part*/
@media (max-width: 576px) {
    div.header-right {
        display: inline-block;
    }

    div.Status-card {
        margin-bottom: 13px;
    }

    div.Status-attended {
        margin-bottom: 0px;
    }

    div.Graph-Container {
        margin-bottom: 13px;
    }
}
