div.PricingPageHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}

div.PricingPageheaderButton {
    display: flex;
}

div.NewLicenseContainer {
    padding-right: 5px;
}

button.NewLicenseButton {
    background: #000000;
    border-radius: 37px;
}

a.NewLicenseText, a.NewLicenseText:hover {
    color: #FFFFFF;
}

div.pricingPageTable {
    border: 0px;
    left: 294px;
    top: 136px;
    background: #FFFFFF;
    border-radius: 7px;
    border: 0px !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07) !important;
}

div.pricingSupportTable th {
    text-align: left;
    white-space: nowrap;
}

div.pricingSupportTable td {
    text-align: left;
}

/*mobile responsive part*/
@media (max-width: 768px) {
    div.PricingPageheaderButton {
        display: inline-block;
    }

    div.NewLicenseContainer {
        padding-bottom: 5px;
    }

    div#company-profile {
        width: 350px;
    }

    div.table-responsive th {
        white-space: nowrap;
    }
}

@media (max-width: 393px) {

    div#company-profile {
        width: 330px;
    }

}

@media (max-width: 375px) {

    div#company-profile {
        width: 300px;
    }
}