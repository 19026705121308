/*.printme {
    display: none;
}*/

/*@page {
    size: A4;
    margin: 0;
}*/

div.detailStatementLogo {
    display: flex;
    justify-content: center;
}

div.detailStatementButton {
    display: flex;
    justify-content: flex-end;
}

table.detailStatementTable th {
    text-align: left;
    white-space: nowrap;
}

table.detailStatementTable td {
    text-align: left;
}


@media print {

    div#Nova-container {
        min-width: 100%;
        margin-left: 0px;
        /*padding: 0px;*/
        display: flex;
        justify-content: center;
        /*width: 21cm;
        height: 29.7cm;*/
        background-color: white;
    }

    div.App {
        margin-top: 0px;
        margin-left: 0px !important;
        display: flex;
        justify-content: center;
    }

    div.rightsidebar {
        display: none;
    }

    div.sidebar-sticky {
        display: none !important;
    }

    a.text-light.nav-link {
        display: none !important;
    }

    div.card-header > div > h5.card-title {
        display: none;
    }

    div.statementId {
        white-space: nowrap;
    }

    button.btn {
        display: none;
    }

    .printme {
        display: block;
        background-color: white;
        /*width: 21cm;
        height: 29.7cm;*/
        /*margin: 5px !important;*/
        /*margin: 30mm 45mm 30mm 45mm;*/
    }
}
