div.monthlyStatementTable {
    border: 0px;
    left: 294px;
    top: 136px;
    background: #FFFFFF;
    border-radius: 7px;
    border: 0px !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07) !important;
}

div.monthlyStatementTable td:nth-child(2) {
    white-space: nowrap;
}
