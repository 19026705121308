/*.App {
  text-align: center;
}
*/
/*body {
    font-size: .875rem;
}
*/
.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

.App {
    margin-top: 2px;
    margin-left: 170px;
}

.App-closed {
    margin-top: 2px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.listgroup-title {
    color: grey;
}

.listgroup-content {
    padding-left: 2em;
}

.padding-default {
    padding: 0.45em;
}

input.form-control {
    border-top: none;
    border-left: none;
    border-right: none;
}

.logo-default {
    width: 64px;
    height: 64px;
}

.icon-slabreach {
    width: 46px;
    height: 46px;
}

.btn-reset,.btn-reset:hover,.btn-reset:focus, .btn-reset:visited {
    color: black;
    text-decoration: none;
}
.btn-reset--active {
    color: red !important;
    text-decoration: none;
}

/*mobile responsive part*/
@media (max-width: 576px) {
    div.App {
        margin-left: 24px;
    }
}

@media (max-width: 768px) {
    div.App-closed {
        margin-left: 40px;
    }

    div.App {
        margin-left: 100px;
    }
}

@media (max-width: 576px) {
    div.App {
        margin-left: 23px;
    }
}

/*@media (max-width: 375 px) {
    div.App {
        margin-left: 23px;
    }
}*/
