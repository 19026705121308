div.UploadDoc-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*border: 1px solid #DCDCDC;*/
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    height: 51px;
}

div.UploadDoc-text {
    padding-left: 11px;
    color: rgba(0, 0, 0, 0.3);
}